import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconFacebookChat: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <radialGradient id="radial0" gradientUnits="userSpaceOnUse" cx="101.925" cy="809.008" fx="101.925" fy="809.008" r="1.09" gradientTransform="matrix(23.99856,0,0,-23.806527,-2441.432794,19283.345319)">
        <stop offset="0" style={{ stopColor: 'rgb(0%,60%,100%)', stopOpacity: 1 }} />
        <stop offset="0.61" style={{ stopColor: 'rgb(62.745098%,20%,100%)', stopOpacity: 1 }} />
        <stop offset="0.935" style={{ stopColor: 'rgb(100%,32.156863%,50.196078%)', stopOpacity: 1 }} />
        <stop offset="1" style={{ stopColor: 'rgb(100%,43.921569%,38.039216%)', stopOpacity: 1 }} />
      </radialGradient>
    </defs>
    <g id="surface1">
      <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'url(#radial0)' }} d="M 12 0 C 5.242188 0 0 4.914062 0 11.546875 C 0 15.015625 1.433594 18.015625 3.769531 20.085938 C 3.964844 20.257812 4.082031 20.503906 4.089844 20.765625 L 4.15625 22.882812 C 4.175781 23.558594 4.878906 24 5.503906 23.726562 L 7.882812 22.683594 C 8.085938 22.59375 8.3125 22.582031 8.527344 22.636719 C 9.621094 22.933594 10.785156 23.09375 12 23.09375 C 18.757812 23.09375 24 18.183594 24 11.550781 C 24 4.917969 18.757812 0 12 0 Z M 12 0 " />
      <path
        style={{
          stroke: 'none', fillRule: 'nonzero', fill: 'rgb(100%,100%,100%)', fillOpacity: 1,
        }}
        d="M 4.792969 14.921875 L 8.320312 9.375 C 8.878906 8.492188 10.082031 8.277344 10.921875 8.902344 L 13.726562 10.988281 C 13.984375 11.175781 14.339844 11.175781 14.59375 10.984375 L 18.378906 8.132812 C 18.882812 7.753906 19.542969 8.351562 19.207031 8.886719 L 15.679688 14.429688 C 15.121094 15.3125 13.917969 15.53125 13.078125 14.90625 L 10.273438 12.820312 C 10.015625 12.628906 9.660156 12.628906 9.40625 12.824219 L 5.621094 15.671875 C 5.117188 16.054688 4.457031 15.457031 4.792969 14.921875 Z M 4.792969 14.921875 "
      />
    </g>
  </BaseIcon>
);
