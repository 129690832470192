import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconViber: FCIcon = (props) => (
  <BaseIcon
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M10.383 0.515297C15.1025 0.536305 17.2197 2.06987 17.7427 2.54884C19.4804 4.05299 20.4462 7.38481 19.7967 12.6451C19.225 17.2963 15.9733 18.1234 14.876 18.4025C14.767 18.4302 14.6792 18.4525 14.6175 18.4727C14.3223 18.5693 11.72 19.2163 8.33751 18.9348C7.96215 19.3676 7.46869 19.9306 7.11019 20.3171C7.00284 20.4324 6.9022 20.5462 6.80594 20.6551C6.31599 21.2095 5.93938 21.6355 5.36834 21.46C4.80318 21.2877 4.8327 20.4306 4.8327 20.4306L4.83692 18.3172H4.8327C-0.114516 16.9307 -0.0428173 11.8174 0.0120112 9.09063C0.0668397 6.35963 0.598255 4.16223 2.10815 2.65808C4.82005 0.179174 10.383 0.515297 10.383 0.515297Z" fill="white" />
    <path d="M12.3274 9.2419C12.1882 9.2419 12.0701 9.13266 12.0617 8.98981C12.0153 8.08647 11.5893 7.64531 10.7205 7.5991C10.5729 7.59069 10.459 7.46465 10.4674 7.31759C10.4759 7.17054 10.6024 7.0571 10.75 7.0655C11.8972 7.12852 12.5383 7.78396 12.5973 8.96039C12.6057 9.10745 12.4919 9.23349 12.3442 9.2377C12.3358 9.2419 12.3316 9.2419 12.3274 9.2419Z" fill="currentColor" />
    <path d="M13.6939 9.69576H13.6896C13.542 9.69156 13.4239 9.56972 13.4281 9.42266C13.4492 8.51513 13.1877 7.77986 12.631 7.17484C12.0785 6.56982 11.3151 6.2421 10.3071 6.17067C10.1595 6.15807 10.0499 6.03202 10.0625 5.88497C10.0752 5.73791 10.2017 5.62867 10.3493 5.64128C11.4881 5.72531 12.3864 6.12025 13.0275 6.81771C13.6728 7.51937 13.9849 8.39749 13.9638 9.43527C13.9596 9.58232 13.8372 9.69576 13.6939 9.69576Z" fill="currentColor" />
    <path d="M15.094 10.2418C14.9464 10.2418 14.8283 10.1242 14.8283 9.9771C14.8157 8.35111 14.3475 7.11165 13.3943 6.18311C12.4538 5.26718 11.2602 4.80081 9.8558 4.7882C9.70818 4.7882 9.59009 4.66636 9.59009 4.51931C9.59009 4.37225 9.7124 4.25461 9.8558 4.25461C11.4036 4.26721 12.7195 4.784 13.7613 5.80077C14.8072 6.81755 15.3429 8.22086 15.3597 9.9687C15.364 10.12 15.2459 10.2418 15.094 10.2418C15.0982 10.2418 15.0982 10.2418 15.094 10.2418Z" fill="currentColor" />
    <path d="M10.7837 12.5316C10.7837 12.5316 11.159 12.5652 11.3615 12.3174L11.7537 11.8258C11.9435 11.5821 12.4032 11.4266 12.8503 11.6745C13.0991 11.8132 13.5504 12.0905 13.8288 12.2963C14.1282 12.5148 14.7355 13.019 14.7397 13.0232C15.0308 13.2669 15.0982 13.624 14.9 14.0064C14.9 14.0064 14.9 14.0106 14.9 14.0148C14.6976 14.3719 14.4234 14.708 14.0818 15.0189C14.0776 15.0189 14.0776 15.0231 14.0734 15.0231C13.7908 15.2584 13.5124 15.3929 13.2425 15.4223C13.2088 15.4307 13.1708 15.4307 13.1202 15.4307C13.0021 15.4307 12.884 15.4139 12.7659 15.3761L12.7575 15.3635C12.3357 15.2458 11.6356 14.9517 10.4716 14.3089C9.7124 13.8929 9.08398 13.4686 8.54835 13.0442C8.26577 12.8215 7.97897 12.5736 7.68374 12.2795C7.67531 12.2711 7.66265 12.2585 7.65422 12.2501C7.64578 12.2417 7.63313 12.2291 7.6247 12.2207C7.61626 12.2123 7.60361 12.1997 7.59517 12.1913C7.58674 12.1829 7.57408 12.1703 7.56565 12.1619C7.27464 11.8678 7.02158 11.5821 6.79805 11.3006C6.37207 10.7712 5.9461 10.141 5.52856 9.38468C4.88327 8.22085 4.58804 7.5234 4.46995 7.10745L4.45729 7.09904C4.41934 6.9814 4.40247 6.86376 4.40247 6.74611C4.40247 6.69569 4.40247 6.65788 4.4109 6.62427C4.44464 6.35117 4.5796 6.07807 4.81157 5.79657C4.81157 5.79236 4.81579 5.79236 4.81579 5.78816C5.12789 5.44364 5.4653 5.17474 5.82379 4.97306C5.82379 4.97306 5.82801 4.97306 5.83223 4.97306C6.21181 4.77559 6.5703 4.84282 6.81914 5.13272C6.81914 5.13272 7.32947 5.74195 7.54878 6.04025C7.75544 6.32176 8.0338 6.76712 8.17298 7.01501C8.42182 7.46037 8.26577 7.91834 8.02115 8.10741L7.52769 8.49815C7.27885 8.69983 7.31259 9.07376 7.31259 9.07376C7.31259 9.07376 8.04224 11.8384 10.7837 12.5316Z" fill="currentColor" />
  </BaseIcon>
);
