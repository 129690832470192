import React, { Children, FC } from 'react';
import { cn } from '@/lib/classNames';
import { useIntersectionObserver } from '@/hooks/useIntersectionObserver';
import { ScrollToTop } from '@/components/ui/ScrollToTop';
import { Button } from '@/components/ui/Button';
import { IconArrowUp } from '@/components/ui/icons/IconArrowUp';
import styles from './FloatIcons.module.scss';
import { FloatIconsTrigger } from './components/FloatIconsTrigger';

interface Props {
  onPlatform?: boolean;
  shouldShowScrollToTop?: boolean;
}

export const FloatIcons: FC<Props> = ({
  onPlatform,
  shouldShowScrollToTop = true,
  children,
}) => {
  const {
    inView: topInView,
    setElementRef: topTrigger,
  } = useIntersectionObserver({
    initialState: true,
    noUnobserve: true,
  });

  return (
    <>
      <div
        className={cn(styles.wrapper, {
          [styles['wrapper--onPlatform']]: onPlatform,
          [styles.pageNotScrolled]: topInView,
        })}
      >
        {Children.map(children, (child) => (
          <div
            className={cn(styles.iconWrapper, {
              [styles.moving]: shouldShowScrollToTop,
            })}
          >
            {child}
          </div>
        ))}

        {shouldShowScrollToTop && (
          <div className={cn(styles.iconWrapper, styles.static)}>
            <ScrollToTop
              renderButton={({ href }) => (
                <Button
                  href={href}
                  LeftIcon={IconArrowUp}
                  mode={Button.mode.TransparentLight}
                  size={Button.size.Small}
                  className={styles.scrollToTopButton}
                />
              )}
            />
          </div>
        )}
      </div>

      <FloatIconsTrigger ref={topTrigger} />
    </>
  );
};
