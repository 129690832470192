import React, { FC } from 'react';
import { SupportLinkGeneric } from '@/components/common/SupportLink';
import { FloatIcons } from '@/components/landing/FloatIcons';
import { useIsOpenStateDeprecated } from '@/hooks/useIsOpenStateDeprecated';
import { useHandleClickOutside } from '@/hooks/useHandleClickOutside';
import floatIconStyles from '@/components/landing/FloatIcons/FloatIcons.module.scss';
import { SupportSocialMedias } from './SupportSocialMedias';

interface Props {
  shouldShowScrollToTop?: boolean;
}

export const SupportSocialMediasCollapsable: FC<Props> = ({
  shouldShowScrollToTop,
}) => {
  const [
    isSupportOpen,
    closeSupport,
    openSupport,
  ] = useIsOpenStateDeprecated();

  useHandleClickOutside(() => {
    if (isSupportOpen) {
      closeSupport();
    }
  }, floatIconStyles.wrapper);

  return (
    <>
      {
        isSupportOpen
          ? (
            <SupportSocialMedias
              shouldShowScrollToTop={shouldShowScrollToTop}
            />
          )
          : (
            <FloatIcons
              onPlatform
              shouldShowScrollToTop={shouldShowScrollToTop}
            >
              <SupportLinkGeneric onClick={openSupport} />
            </FloatIcons>
          )
      }
    </>
  );
};
