import { SupportSocialNames } from '@/controllers/graphql/generated';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useGetSupportSocialsQuery } from '@/components/common/SupportButtons/graphql/generated/getSupportSocials.query.generated';

export const useSupportSocials = () => {
  const { subDomain, isKnowelyProduct } = useSubDomainContext();

  const result = useGetSupportSocialsQuery({
    variables: { domain: subDomain },
  });

  if (isKnowelyProduct) {
    return [
      {
        id: 1,
        name: SupportSocialNames.Whatsapp,
        source: 'https://wa.me/14844458770?text=Start%20conversation',
      },
    ];
  }

  return result.data?.getSupportSocials ?? [];
};
