import React, { FC } from 'react';
import { PageTop } from './components/PageTop';

interface Props {
  renderButton: (options: { href: string }) => React.ReactElement;
}
export const ScrollToTop: FC<Props> = (props) => {
  const { renderButton } = props;

  return (
    <>
      <PageTop />
      {renderButton({ href: '#page-top' })}
    </>
  );
};
