import React, { FC, useMemo } from 'react';
import { cn } from '@/lib/classNames';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { LogoIconWhatsapp } from '@/components/ui/icons/logos/LogoIconWhatsapp';
import { IconViber } from '@/components/ui/icons/IconViber';
import { SupportSocialNames } from '@/controllers/graphql/generated';
import { IconFacebookChat } from '@/components/ui/icons/IconFacebookChat';
import { LogoIconTelegram } from '@/components/ui/icons/logos/LogoIconTelegram';
import { analyticsSDK } from '@/controllers/analytics';
import style from './SupportLink.module.scss';

const iconComponents: Record<SupportSocialNames, FCIcon> = {
  [SupportSocialNames.Telegram]: LogoIconTelegram,
  [SupportSocialNames.Whatsapp]: LogoIconWhatsapp,
  [SupportSocialNames.Viber]: IconViber,
  [SupportSocialNames.Facebook]: IconFacebookChat,
};

interface Props {
  href: string;
  providerName: SupportSocialNames;
}

type SupportLinkType = FC<Props> & {
  providerName: typeof SupportSocialNames;
};

export const SupportLink: SupportLinkType = (props) => {
  const { t } = useTranslation([I18N_CODES.common]);
  const { href, providerName } = props;

  const sendAnalytics = () => {
    analyticsSDK.global.sendSupportIconClickedEvent({
      providerName,
    });
  };

  const { Icon, content } = useMemo(() => ({
    Icon: iconComponents[providerName],
    content: t(`${I18N_CODES.common}:${providerName.toLowerCase()}_icon_content`),
  }), [providerName, t]);

  return (
    <a
      href={href}
      rel="nofollow noopener noreferrer"
      target="_blank"
      onClick={sendAnalytics}
      title={content}
      aria-label={content}
      data-qa='support-link-button'
    >
      <span
        data-content={content}
        className={cn(style.container, {
          [style.telegram]: providerName === SupportSocialNames.Telegram,
          [style.whatsapp]: providerName === SupportSocialNames.Whatsapp,
          [style.viber]: providerName === SupportSocialNames.Viber,
          [style.facebook]: providerName === SupportSocialNames.Facebook,
        })}
      >
        <Icon className={style.icon} color='#fff' />
      </span>
    </a>
  );
};

SupportLink.providerName = SupportSocialNames;
