import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconChatWithDots: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M36.5 12.599C36.5 11.11 36.5 10.3655 36.3042 9.76296C35.9086 8.5452 34.9538 7.59047 33.7361 7.1948C33.1335 6.99902 32.389 6.99902 30.9 6.99902H10.1C8.61098 6.99902 7.86646 6.99902 7.26393 7.1948C6.04618 7.59047 5.09145 8.5452 4.69577 9.76296C4.5 10.3655 4.5 11.11 4.5 12.599V34.2662C4.5 34.9954 4.5 35.3599 4.60157 35.5677C4.80931 35.9928 5.28647 36.2141 5.74512 36.0981C5.96937 36.0414 6.24769 35.8059 6.80432 35.3349L11.9286 30.999H30.9C32.389 30.999 33.1335 30.999 33.7361 30.8033C34.9538 30.4076 35.9086 29.4528 36.3042 28.2351C36.5 27.6326 36.5 26.8881 36.5 25.399V12.599ZM13.5 16.999C12.3954 16.999 11.5 17.8945 11.5 18.999C11.5 20.1036 12.3954 20.999 13.5 20.999C14.6046 20.999 15.5 20.1036 15.5 18.999C15.5 17.8945 14.6046 16.999 13.5 16.999ZM18.5 18.999C18.5 17.8945 19.3954 16.999 20.5 16.999C21.6046 16.999 22.5 17.8945 22.5 18.999C22.5 20.1036 21.6046 20.999 20.5 20.999C19.3954 20.999 18.5 20.1036 18.5 18.999ZM27.5 16.999C26.3954 16.999 25.5 17.8945 25.5 18.999C25.5 20.1036 26.3954 20.999 27.5 20.999C28.6046 20.999 29.5 20.1036 29.5 18.999C29.5 17.8945 28.6046 16.999 27.5 16.999Z" fill="currentColor" />
  </BaseIcon>
);
