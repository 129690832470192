import { useCallback, useEffect } from 'react';

export const useHandleClickOutside = (
  cb: (event: MouseEvent) => void,
  markerClassName: string,
) => {
  const listener = useCallback((event: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!event.target.closest(`.${markerClassName}`)) {
      cb(event);
    }
  }, [cb, markerClassName]);

  useEffect(() => {
    window.addEventListener('click', listener);

    return () => window.removeEventListener('click', listener);
  }, [listener]);
};
