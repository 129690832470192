import React, { forwardRef } from 'react';
import { Portal } from '@/components/ui/Portal';
import styles from './FloatIconsTrigger.module.scss';

export const FloatIconsTrigger = forwardRef<HTMLDivElement>(
  (_, ref) => (
    <Portal>
      <div className={styles.triggerElement} ref={ref} />
    </Portal>
  ),
);
